import React, { useState, useEffect } from "react"
import { Link } from "react-scroll"
import useStore from "../../state"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"

import {
  header_bg,
  header,
  header_content,
  title,
  mobile_nav_toggle,
  mobile_nav_toggle_content,
  mobile_nav_toggle_index,
  say_hi_link,
} from "./header.module.css"
import Navigation from "./Navigation"

const Header = ({ showMenu = true }) => {
  // Nav State
  const [isMobileNavIndexToggled, toggleMobileNavIndex] = useState(false)

  const isNavToggled = useStore((state) => state.isNavToggled)
  const toggleNav = useStore((state) => state.toggleNav)

  // Hook to update State
  const currentProject = useStore((state) => state.currentProject)

  // fetch Contentful data
  const data = useStaticQuery(graphql`
    query {
      contentfulStartseite {
        projects {
          name
          slug
        }
      }
    }
  `)
  const projects = data.contentfulStartseite.projects

  useEffect(() => {
    // toggle index showing up on mobile
    if (currentProject?.id) {
      toggleMobileNavIndex(true)

      !isMobileNavIndexToggled &&
        setTimeout(() => {
          toggleMobileNavIndex(false)
        }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject])

  return (
    <>
      {showMenu && (
        <div className={header_bg} data-nav-toggled={isNavToggled} />
      )}

      <header className={header}>
        <div className={header_content}>
          <h1 className={title}>
            {showMenu ? (
              <Link
                href="#projects"
                to="projects"
                onClick={() => toggleNav(false)}
                smooth={true}
                duration={1200}
              >
                Marie Grob
              </Link>
            ) : (
              <GatsbyLink to="/">Marie Grob</GatsbyLink>
            )}
          </h1>

          {/* mobile nav toggle */}
          {showMenu && (
            <>
              <button
                className={mobile_nav_toggle}
                onClick={() => toggleNav(!isNavToggled)}
              >
                <span
                  className={mobile_nav_toggle_content}
                  data-index-toggled={isMobileNavIndexToggled}
                >
                  {isNavToggled ? (
                    <span>
                      <span className="inline-block mr-1" aria-hidden="true">
                        x
                      </span>
                      close
                    </span>
                  ) : (
                    "menue"
                  )}
                </span>
                <span className={mobile_nav_toggle_index}>
                  {currentProject ? "0" + (+currentProject.index + 1) : "01"}
                </span>
              </button>
              {/* Navigation */}
              <Navigation items={projects} />
            </>
          )}
        </div>

        {showMenu && (
          <GatsbyLink
            to="/sayhi"
            onClick={() => toggleNav(false)}
            className={say_hi_link}
          >
            say hi
          </GatsbyLink>
        )}
      </header>
    </>
  )
}

export default Header
