import create from "zustand"

export const useStore = create((set) => ({
  // projects
  currentProject: undefined,
  setProject: (projectId) => {
    const project = document.getElementById(projectId)

    set({
      currentProject: projectId
        ? {
            id: projectId,
            type: project.getAttribute("data-type"),
            index: +project.getAttribute("data-index"),
            imagesCount: +project.getAttribute("data-images-count"),
          }
        : undefined,
    })
  },

  // nav
  isNavToggled: false,
  toggleNav: (newState) => {
    newState === false
      ? document.body.classList.remove("no-overflow")
      : document.body.classList.add("no-overflow")

    set({ isNavToggled: newState })
  },
}))

export default useStore
