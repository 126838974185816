import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link } from "react-scroll"
import { nav, nav_item, active } from "./navigation.module.css"

import useStore from "../../../state"

const Navigation = ({ items }) => {
  const isNavToggled = useStore((state) => state.isNavToggled)
  const toggleNav = useStore((state) => state.toggleNav)

  const setProject = useStore((state) => state.setProject)

  return (
    <nav className={nav} data-toggled={isNavToggled}>
      <ol className="my-10">
        {items.map((project) => (
          <li key={project.slug}>
            <Link
              to={`${project.slug}`}
              href={`#${project.slug}`}
              tabIndex={0}
              duration={1200}
              offset={-16}
              smooth
              spy
              hashSpy
              isDynamic
              className={nav_item}
              activeClass={active}
              onClick={() => toggleNav(false)} // close mobile menu on click
              onSetActive={(projectId) => setProject(projectId)} // update current project
              onSetInactive={
                () => window.location.hash || setProject(undefined) // "remove" current project when on top of page
              }
            >
              {project.name}
            </Link>
          </li>
        ))}
      </ol>

      <GatsbyLink
        to="/sayhi"
        className="lg:hidden"
        onClick={() => toggleNav(false)}
      >
        say hi
      </GatsbyLink>
    </nav>
  )
}

export default Navigation
